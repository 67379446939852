<template>
  <section class="mt-2">
    <b-form inline class="mt-2">
      <b-button @click="refreshList" class="no-border"
        ><i class="fal fa-sync"></i
      ></b-button>
      <b-form-input trim
        v-model="searchText"
        :placeholder="$t('SEARCH')"
        @keydown.enter.prevent
        class="searchText"
      ></b-form-input>
      <b-form-select v-model="limit" class="limit" name="eventlog_limit" @change="getItems">
        <b-form-select-option value="10">10</b-form-select-option>
        <b-form-select-option value="25">25</b-form-select-option>
        <b-form-select-option value="45">45</b-form-select-option>
        <b-form-select-option value="100">100</b-form-select-option>
      </b-form-select>
    </b-form>

    <b-table
      :key="eventlogKey"
      small
      class="mt-2"
      :busy="loading"
      :items="items"
      hover
      :fields="fields"
    >
      <template v-slot:cell(created_date)="data">{{ FormatDateTime(data.item.created_date) }}</template>

      <template v-slot:cell(type)="data">
        {{ showAction(data.item) }}
      </template>

      <template v-slot:cell(created_name)="data">
        <div v-if="data.item.delegated_account_email">
          {{ data.item.delegated_account_name }},
          {{ data.item.delegated_account_email }}
        </div>
        <div v-if="!data.item.delegated_account_email">
          {{ data.item.created_account_name }},
          {{ data.item.created_organisation_name }}
        </div>
      </template>

      <template v-slot:cell(organisation_name)="data">
        {{ showTarget(data.item) }}
      </template>

      <template v-slot:cell(changed_from)="data">
        <span v-if="data.item.changed_from != ''">{{ checkValue(data.item.changed_from) }}
          <span v-if="data.item.changed_to != ''"> <i class="fa-solid fa-arrow-right"></i> {{ checkValue(data.item.changed_to) }}</span>
        </span>
        <span v-if="data.item.changed_from == '' && data.item.changed_to != ''">{{ checkValue(data.item.changed_to) }}</span>
      </template>

      <template v-slot:cell(id)="data">
        <small>{{
          FormatDateTime(data.item.created_date)
        }}</small>
        <br />
        {{ showAction(data.item) }}
        <br />
        {{ data.item.created_account_name }},
        {{ data.item.created_organisation_name }}
        <br />
        {{ showTarget(data.item) }}
      </template>

      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="totalItems"
      :per-page="limit"
      align="center"
      @input="getItems"
      pills
    >
    </b-pagination>
  </section>
</template>
<script>
export default {
  props: [
    "organisation",
    "type",
    "message_uuid",
    "functionbox_uuid",
    "user_uuid",
  ],
  data() {
    return {
      eventlogIndex: 0,
      searchText: "",
      limit: 10,
      filter_status: [],
      clickedIndex: 0,
      bulk_action: 0,
      bulk_options: [
        { value: 0, text: this.$t("RESEND") },
        { value: 1, text: this.$t("REMOVE") },
      ],
      loading: false,
      add_all: false,
      show_sidebar: false,
      currentSearch: this.searchText,
      currentLimit: this.limit,
      currentPage: 1,
      selected: [],
      items: [],
      totalItems: 0,
      state: 1,
      fields: [
        {
          key: "id",
          class: "hidden-not-mobile",
          thClass: "hidden",
        },
        {
          key: "created_date",
          label: this.$t("CREATED"),
          class: "text-break w-date clickable hidden-mobile",
          thClass: "hidden-mobile",
          sortable: false,
        },
        {
          key: "created_name",
          label: "",
          class: "text-break clickable hidden-mobile",
          thClass: "hidden-mobile",
          sortable: false,
        },
        {
          key: "type",
          label: "",
          class: "text-break clickable hidden-mobile",
          thClass: "hidden-mobile",
          sortable: false,
        },
        {
          key: "changed_from",
          label: this.$t("FROM"),
          class: "text-break clickable hidden-mobile",
          thClass: "hidden-mobile",
          sortable: false,
        },
        {
          key: "organisation_name",
          label: "",
          class: "text-break clickable hidden-mobile",
          thClass: "hidden-mobile",
          sortable: false,
        },
      ],
      auth_methods: [],
      custom_auth_methods: []
    };
  },
  methods: {
    checkValue(value)
    {
      if(value == "True" || value == "False")
      {
        return value == "True" ? this.$t('ENABLED') : this.$t('DISABLED');
      }
      if(value == "1" || value == "0")
      {
        return value == "1" ? this.$t('ENABLED') : this.$t('DISABLED');
      }
      return value;
    },
    showAction(logItem) {
      /*let logSetting = this.logItems.filter(function(item) {
        return item.key == logItem.action;
      })[0];*/
      let logReturn = this.$t("EVENTLOG." + logItem.action);
      if(logItem.type == "MsTeamsSetting")
      {
        logReturn = this.$t('MESSAGES.TYPE.teams-meeting') + " " + this.$t('SETTING').toLowerCase() + " - " + logReturn;
      }
      if(logItem.type == "JitsiSecureSetting")
      {
        logReturn = this.$t('MESSAGES.TYPE.jitsi-secure-meeting') + " " + this.$t('SETTING').toLowerCase() + " - " + logReturn;
      }
      if(logItem.type == "JitsiSetting")
      {
        logReturn = this.$t('MESSAGES.TYPE.jitsi-meeting') + " " + this.$t('SETTING').toLowerCase() + " - " + logReturn;
      }
      if(logItem.type == "NuiteqSetting")
      {
        logReturn = this.$t('MESSAGES.TYPE.nuiteq-meeting') + " " + this.$t('SETTING').toLowerCase() + " - " + logReturn;
      }
      if(logItem.type == "SefosMessageSetting")
      {
        logReturn = this.$t('MESSAGES.TYPE.message') + " " + this.$t('SETTING').toLowerCase() + " - " + logReturn;
      }
      if(logItem.type == "AddressbookSetting")
      {
        logReturn = this.$t('ADDRESSBOOK.TITLE') + " " + this.$t('SETTING').toLowerCase() + " - " + logReturn;
      }
      /*if (logSetting != undefined) {
        if (logSetting.show == "changed_from") {
          logReturn = logReturn + " " + logItem.changed_from;
        }
        if (logSetting.show == "changed_to") {
          logReturn = logReturn + " " + logItem.changed_to;
        }
        if (logSetting.show == "from_to") {
          logReturn =
            logReturn +
            " " +
            this.$t("FROM")
              .toLowerCase()
              .trim() +
            " " +
            logItem.changed_from +
            " " +
            this.$t("TO")
              .toLowerCase()
              .trim() +
            " " +
            logItem.changed_to;
        }
        if (logSetting.show == "delegated") {
          logReturn =
            logReturn +
            " " +
            logItem.changed_to +
            " " +
            this.$t("TO")
              .toLowerCase()
              .trim() +
            " " +
            logItem.changed_from;
        }
        if (logSetting.show == "auth") {
          logReturn = logReturn + " ";
          if (logItem.changed_from != "") {
            if (logItem.changed_from == "custom") {
              logReturn =
                logReturn +
                this.$t("FROM")
                  .toLowerCase()
                  .trim() +
                " " +
                this.$t("AUTH_METHODS.CUSTOM_METHODS");
            } else {
              let checkInt = parseInt(logItem.changed_from);
              if (isNaN(checkInt)) {
                logReturn =
                  logReturn +
                  this.$t("FROM")
                    .toLowerCase()
                    .trim() +
                  " " +
                  this.$t("AUTH_METHODS." + logItem.changed_from);
              } else {
                let filtered = this.custom_auth_methods.filter(
                  (c) => c.id == checkInt
                );
                if (filtered[0].name != undefined) {
                  logReturn =
                    logReturn +
                    this.$t("FROM")
                      .toLowerCase()
                      .trim() +
                    " " +
                    filtered[0].name;
                } else {
                  logReturn =
                    logReturn +
                    this.$t("FROM")
                      .toLowerCase()
                      .trim() +
                    " " +
                    this.$t("AUTH_METHODS." + logItem.changed_from);
                }
              }
            }
          }
          if (logItem.changed_to != "") {
            if (logItem.changed_from != "") {
              logReturn = logReturn + " ";
            }
            if (logItem.changed_to == "custom") {
              logReturn =
                logReturn +
                this.$t("TO")
                  .toLowerCase()
                  .trim() +
                " " +
                this.$t("AUTH_METHODS.CUSTOM_METHODS");
            } else {
              let checkInt = parseInt(logItem.changed_to);
              if (isNaN(checkInt)) {
                logReturn =
                  logReturn +
                  this.$t("TO")
                    .toLowerCase()
                    .trim() +
                  " " +
                  this.$t("AUTH_METHODS." + logItem.changed_to);
              } else {
                let filtered = this.custom_auth_methods.filter(
                  (c) => c.id == checkInt
                );
                if (filtered[0].name != undefined) {
                  logReturn =
                    logReturn +
                    this.$t("TO")
                      .toLowerCase()
                      .trim() +
                    " " +
                    filtered[0].name;
                } else {
                  logReturn =
                    logReturn +
                    this.$t("TO")
                      .toLowerCase()
                      .trim() +
                    " " +
                    this.$t("AUTH_METHODS." + logItem.changed_to);
                }
              }
            }
          }
        }
        if (logSetting.show == "active_inactive") {
          logReturn =
            logReturn +
            " " +
            this.$t("FROM")
              .toLowerCase()
              .trim() +
            " " +
            this.active_inactive(logItem.changed_from) +
            " " +
            this.$t("TO")
              .toLowerCase()
              .trim() +
            " " +
            this.active_inactive(logItem.changed_to);
        }
        if (logSetting.show == "yes_no") {
          logReturn =
            logReturn +
            " " +
            this.$t("FROM")
              .toLowerCase()
              .trim() +
            " " +
            this.yes_no(logItem.changed_from) +
            " " +
            this.$t("TO")
              .toLowerCase()
              .trim() +
            " " +
            this.yes_no(logItem.changed_to);
        }
      }*/
      return logReturn;
    },
    showTarget(logItem) {
      let targetReturn = "";
      if (logItem.type == "functionbox") {
        targetReturn =
          logItem.functionbox_name;// + ", " + logItem.functionbox_email;
      }
      if (logItem.type == "organisation") {
        if (logItem.organisation_id != logItem.created_organisation_id) {
          targetReturn = logItem.organisation_name;
        }
      }
      if (logItem.type == "account") {
        if (logItem.account_user_uuid != logItem.created_account_user_uuid) {
          targetReturn = logItem.account_name;
        }
      }
      if (logItem.type == "authenticationMethod") {
        targetReturn = this.getAuthMethod(logItem.event_id);
      }
      if (logItem.type == "customAuthenticationMethod") {
        targetReturn = this.getCustomAuthMethod(logItem.event_id);
      }
      return targetReturn;
    },
    getFunctionbox(id) {
      let box = this.functionboxes.filter(function(item) {
        return item.id !== id;
      })[0];
      return box.name + ", " + box.email;
    },
    yes_no(value) {
      if (value == 1) {
        return this.$t("YES");
      } else {
        return this.$t("NO");
      }
    },
    active_inactive(value) {
      if (value == 1) {
        return this.$t("ACTIVE");
      } else {
        return this.$t("INACTIVE");
      }
    },
    refreshList() {
      this.eventlogIndex = this.eventlogIndex + 1;
      this.getItems();
    },
    getMethods: function() {
      let self = this;
      this.$http
        .get(
          this.user.hostname + "/administration/authentications/list"
        )
        .then((response) => {
          self.auth_methods = response.data.items;
        })
        .catch(() => {
        });
    },
    getCustomMethods: function() {
      let self = this;
      this.$http
        .get(
          this.user.hostname +
            "/administration/authentication/custom/list"
        )
        .then((response) => {
          self.custom_auth_methods = response.data.items;
        })
        .catch(() => {
        });
    },
    clearFilterStatus() {
      this.filter_status = [];
      this.getItems();
    },
    clearFilterAll() {
      this.filter_status = [];
      this.getItems();
    },
    addAll: function() {
      if (this.add_all) {
        let filtered = this.items.filter(function(item) {
          return item.type == "invite";
        });
        this.selected = filtered.map((a) => a.id);
      } else {
        this.selected = [];
      }
    },
    showState: function(state) {
      if (state === 1) {
        return this.$t("ACTIVE");
      }
      return this.$t("INACTIVE");
    },
    showAdmin: function(is_admin) {
      return this.$t("ADMINLEVEL." + is_admin);
    },
    getAuthMethod: function(method_id) {
      let method = this.auth_methods.filter(function(item) {
        return item.id == method_id;
      })[0];
      if (method == undefined) {
        return "";
      } else {
        if (method.auth_method == "") {
          return this.$t("AUTH_METHODS." + method.auth_type);
        } else {
          return this.$t("AUTH_METHODS." + method.auth_method);
        }
      }
    },
    getCustomAuthMethod: function(method_id) {
      let method = this.custom_auth_methods.filter(function(item) {
        return item.id == method_id;
      })[0];
      if (method == undefined) {
        return "";
      } else {
        return method.name;
      }
    },
    getItems: function() {
      if (this.type == "admin") {
        this.getLogAdminItems();
      }
      if (this.type == "organisation") {
        this.getLogOrganisationItems();
      }
      if (this.type == "authmethods") {
        this.getLogAuthMethodsChanges();
      }
      if (this.type == "message-functionbox") {
        this.getLogMessageFunctionbox();
      }
      if (this.type == "message-delegated-account") {
        this.getLogMessageDelegatedAccount();
      }
    },
    getLogMessageDelegatedAccount: function() {
      this.loading = true;
      let self = this;
      this.$http
        .post(this.user.hostname + "/eventlog/delegated/message/list", {
          page: this.currentPage,
          limit: parseInt(this.limit),
          search_text: this.searchText,
          user_uuid: this.user_uuid,
          message_uuid: this.message_uuid,
        })
        .then((response) => {
          self.loading = false;
          self.items = response.data.items;
          self.totalItems = response.data.count;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    getLogMessageFunctionbox: function() {
      this.loading = true;
      let self = this;
      this.$http
        .post(
          this.user.hostname + "/eventlog/functionbox/message/list",
          {
            page: this.currentPage,
            limit: parseInt(this.limit),
            search_text: this.searchText,
            functionbox_uuid: this.functionbox_uuid,
            message_uuid: this.message_uuid,
          }
        )
        .then((response) => {
          self.loading = false;
          self.items = response.data.items;
          self.totalItems = response.data.count;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    getLogOrganisationItems: function() {
      this.getMethods();
      this.getCustomMethods();
      this.loading = true;
      let self = this;
      this.$http
        .post(this.user.hostname + "/eventlog/organisation/list", {
          page: this.currentPage,
          organisation_id: this.organisation.id,
          limit: parseInt(this.limit),
          search_text: this.searchText,
        })
        .then((response) => {
          self.loading = false;
          self.items = response.data.items;
          self.totalItems = response.data.count;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    getLogAuthMethodsChanges: function() {
      this.getMethods();
      this.getCustomMethods();
      this.loading = true;
      let self = this;
      this.$http
        .post(this.user.hostname + "/eventlog/authentications", {
          page: this.currentPage,
          limit: parseInt(this.limit),
          search_text: this.searchText,
          filter_type: ["5"],
        })
        .then((response) => {
          self.loading = false;
          self.items = response.data.items;
          self.totalItems = response.data.count;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    getLogAdminItems: function() {
      this.getMethods();
      this.getCustomMethods();
      this.loading = true;
      let self = this;
      this.$http
        .post(this.user.hostname + "/eventlog/all", {
          page: this.currentPage,
          limit: parseInt(this.limit),
          search_text: this.searchText,
        })
        .then((response) => {
          self.loading = false;
          self.items = response.data.items;
          self.totalItems = response.data.count;
        })
        .catch(() => {
          self.loading = false;
        });
    },
  },
  computed: {
    showFilterClearAll() {
      if (this.filter_status.length != 0) {
        return true;
      }
      return false;
    },
    filterClassStatus() {
      if (this.filter_status.length != 0) {
        return ["filter-selected", "no-border"];
      } else {
        return ["no-border"];
      }
    },
    filterTextStatus() {
      if (this.filter_status.length != 0) {
        if (this.filter_status.length > 1) {
          return (
            this.$t("FILTER.STATUS_ACCOUNTS.TITLE") +
            ": " +
            this.$t(
              "FILTER.STATUS_ACCOUNTS." + this.filter_status.slice().sort()[0]
            ) +
            " +(" +
            (this.filter_status.length - 1) +
            ")"
          );
        } else {
          return (
            this.$t("FILTER.STATUS_ACCOUNTS.TITLE") +
            ": " +
            this.$t(
              "FILTER.STATUS_ACCOUNTS." + this.filter_status.slice().sort()[0]
            )
          );
        }
      } else {
        return this.$t("FILTER.STATUS_ACCOUNTS.TITLE");
      }
    },
    eventlogKey: function() {
      return "eventlog_" + this.eventlogIndex;
    },
  },
  watch: {
    filter_status: function(new_array) {
      localStorage.setItem("organisation_users", JSON.stringify(new_array));
    },
    searchText: function() {
      if (this.searchText.length == 0) {
        this.getItems();
      }
      if (this.searchText.length >= 2) {
        this.getItems();
      }
    },
  },
  mounted: function() {
    if (localStorage.getItem("organisation_users") !== null) {
      this.filter_status = JSON.parse(
        localStorage.getItem("organisation_users")
      );
    }
    this.getItems();
  },
};
</script>
<style></style>
