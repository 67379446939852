var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "mt-2" },
    [
      _c(
        "b-form",
        { staticClass: "mt-2", attrs: { inline: "" } },
        [
          _c(
            "b-button",
            { staticClass: "no-border", on: { click: _vm.refreshList } },
            [_c("i", { staticClass: "fal fa-sync" })]
          ),
          _c("b-form-input", {
            staticClass: "searchText",
            attrs: { trim: "", placeholder: _vm.$t("SEARCH") },
            on: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                $event.preventDefault()
              },
            },
            model: {
              value: _vm.searchText,
              callback: function ($$v) {
                _vm.searchText = $$v
              },
              expression: "searchText",
            },
          }),
          _c(
            "b-form-select",
            {
              staticClass: "limit",
              attrs: { name: "eventlog_limit" },
              on: { change: _vm.getItems },
              model: {
                value: _vm.limit,
                callback: function ($$v) {
                  _vm.limit = $$v
                },
                expression: "limit",
              },
            },
            [
              _c("b-form-select-option", { attrs: { value: "10" } }, [
                _vm._v("10"),
              ]),
              _c("b-form-select-option", { attrs: { value: "25" } }, [
                _vm._v("25"),
              ]),
              _c("b-form-select-option", { attrs: { value: "45" } }, [
                _vm._v("45"),
              ]),
              _c("b-form-select-option", { attrs: { value: "100" } }, [
                _vm._v("100"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-table", {
        key: _vm.eventlogKey,
        staticClass: "mt-2",
        attrs: {
          small: "",
          busy: _vm.loading,
          items: _vm.items,
          hover: "",
          fields: _vm.fields,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(created_date)",
            fn: function (data) {
              return [
                _vm._v(_vm._s(_vm.FormatDateTime(data.item.created_date))),
              ]
            },
          },
          {
            key: "cell(type)",
            fn: function (data) {
              return [
                _vm._v(
                  "\n      " + _vm._s(_vm.showAction(data.item)) + "\n    "
                ),
              ]
            },
          },
          {
            key: "cell(created_name)",
            fn: function (data) {
              return [
                data.item.delegated_account_email
                  ? _c("div", [
                      _vm._v(
                        "\n        " +
                          _vm._s(data.item.delegated_account_name) +
                          ",\n        " +
                          _vm._s(data.item.delegated_account_email) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
                !data.item.delegated_account_email
                  ? _c("div", [
                      _vm._v(
                        "\n        " +
                          _vm._s(data.item.created_account_name) +
                          ",\n        " +
                          _vm._s(data.item.created_organisation_name) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(organisation_name)",
            fn: function (data) {
              return [
                _vm._v(
                  "\n      " + _vm._s(_vm.showTarget(data.item)) + "\n    "
                ),
              ]
            },
          },
          {
            key: "cell(changed_from)",
            fn: function (data) {
              return [
                data.item.changed_from != ""
                  ? _c("span", [
                      _vm._v(
                        _vm._s(_vm.checkValue(data.item.changed_from)) +
                          "\n        "
                      ),
                      data.item.changed_to != ""
                        ? _c("span", [
                            _c("i", { staticClass: "fa-solid fa-arrow-right" }),
                            _vm._v(
                              " " + _vm._s(_vm.checkValue(data.item.changed_to))
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                data.item.changed_from == "" && data.item.changed_to != ""
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.checkValue(data.item.changed_to))),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(id)",
            fn: function (data) {
              return [
                _c("small", [
                  _vm._v(_vm._s(_vm.FormatDateTime(data.item.created_date))),
                ]),
                _c("br"),
                _vm._v(
                  "\n      " + _vm._s(_vm.showAction(data.item)) + "\n      "
                ),
                _c("br"),
                _vm._v(
                  "\n      " +
                    _vm._s(data.item.created_account_name) +
                    ",\n      " +
                    _vm._s(data.item.created_organisation_name) +
                    "\n      "
                ),
                _c("br"),
                _vm._v(
                  "\n      " + _vm._s(_vm.showTarget(data.item)) + "\n    "
                ),
              ]
            },
          },
          {
            key: "table-busy",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "text-center my-2" },
                  [_c("b-spinner", { staticClass: "align-middle" })],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("b-pagination", {
        attrs: {
          "total-rows": _vm.totalItems,
          "per-page": _vm.limit,
          align: "center",
          pills: "",
        },
        on: { input: _vm.getItems },
        model: {
          value: _vm.currentPage,
          callback: function ($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }